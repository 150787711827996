import Link from 'next/link';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { ModalProvider } from 'styled-react-modal';

import { CarouselRow } from 'components/common/carousel';
import Container from 'components/common/container';
import { useCookieAccepted } from 'components/common/cookie-banner/utils';
import { Close } from 'components/common/icons';
import Image from 'components/common/image';
import ModalNotification from 'components/common/modal-notification';
import { CloseButton } from 'components/common/modal-notification/styles';
import ResponsiveBox from 'components/common/responsive-box';
import Section from 'components/common/section';
import { Heading2, HeadingLink, HeadingWrapper } from 'components/common/text';
import { IHeadingLink } from 'components/common/text/heading-link/types';
import AddToNotification from 'components/product/add-to-basket-notification';
import { getModalBody } from 'components/product/product-grid/helpers';
import { useQuickBuyAddToBag } from 'components/product/product-grid/hooks';
import {
  CloseButtonWrapper,
  ModalBody,
  OuickBuySpecialModalBackground,
  QuickBuyModalWrapper,
} from 'components/product/product-grid/styles';
import { desktop, mobile, tablet } from 'utils/media';

import ProductCarouselItem from './product-carousel-item';
import {
  TitleSpacer,
  TabsRow,
  TabLink,
  ProductCarouselWrapper,
  HeaderContainer,
} from './styles';

export interface ProductCarouselTab {
  _key: string;
  title: ReactNode;
  titleLink?: IHeadingLink;
  description?: string;
  image?: Sanity.ImageAsset;
  altText?: string;
  enableQuickBuy?: boolean;
  products?: Sproutl.ProductListing[];
}

export interface ProductCarouselProps {
  tabs?: ProductCarouselTab[];
  extendedWidth?: boolean;
  scrollBarReveal?: boolean;
}

export default function ProductCarousel({
  tabs = [],
  extendedWidth = true,
  scrollBarReveal = false,
}: ProductCarouselProps) {
  const [selectedTabId, setSelectedTabId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedTab = tabs[selectedTabId];

  const { enableQuickBuy, hasImage } = useMemo(() => {
    return {
      enableQuickBuy: !!selectedTab?.enableQuickBuy,
      hasImage: !!selectedTab?.image,
    };
  }, [selectedTab]);

  const [modalContent, setModalContent] =
    useState<Sproutl.ProductListing | null>(null);

  const { postAddToBag, isAddingToBag, addedToBag } = useQuickBuyAddToBag(
    modalContent,
    { bestOffer: modalContent?.bestOffer || null },
    1,
  );

  const hasCookieAccepted = useCookieAccepted();

  const modalBodyArgs = useMemo(
    () => ({
      product: modalContent || undefined,
      setIsModalOpen,
      postAddToBag,
      isAddingToBag,
      hasCookieAccepted,
    }),
    [
      modalContent,
      setIsModalOpen,
      postAddToBag,
      isAddingToBag,
      hasCookieAccepted,
    ],
  );

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const productCount = tabs.reduce(
    (count, tab) => count + (tab.products?.length || 0),
    0,
  );

  if (!tabs.length || !productCount) {
    return null;
  }

  const { text, url } = selectedTab?.titleLink || {};

  return (
    <>
      <Section bg="normal">
        <Container noPadding noGutter={extendedWidth}>
          <HeaderContainer>
            <TitleSpacer>
              <HeadingWrapper>
                <Heading2 inline>{selectedTab.title}</Heading2>
                {text && url && (
                  <Link href={url} passHref>
                    <HeadingLink href={url}>{text}</HeadingLink>
                  </Link>
                )}
              </HeadingWrapper>
              {tabs.length > 1 && (
                <TabsRow>
                  {tabs.map((tab, index) => (
                    <TabLink
                      key={index}
                      onClick={() => setSelectedTabId(index)}
                      active={index === selectedTabId}
                    >
                      {tab.title}
                    </TabLink>
                  ))}
                </TabsRow>
              )}
            </TitleSpacer>
            {selectedTab.description ? <p>{selectedTab.description}</p> : null}
          </HeaderContainer>
          <ProductCarouselWrapper hasImage={hasImage}>
            {hasImage && (
              <figure>
                <ResponsiveBox aspectRatio={0.96328}>
                  <Image
                    src={selectedTab.image}
                    aspectRatio={1}
                    sizes={`${mobile} calc(100vw - var(--space-2xl)), ${tablet} calc(100vw - var(--space-5xl)), ${desktop} 463px`}
                    alt={selectedTab.altText || ''}
                  />
                </ResponsiveBox>
              </figure>
            )}
            <CarouselRow
              scrollBarReveal={scrollBarReveal}
              arrowLayout={hasImage ? 'overlay' : undefined}
            >
              {selectedTab.products?.map((product) => {
                if (!product || !product.sku) {
                  return null;
                }
                return (
                  <ProductCarouselItem
                    product={product}
                    key={product.sku}
                    enableQuickBuy={enableQuickBuy}
                    {...(enableQuickBuy
                      ? {
                          toggleQuickBuyModal: setIsModalOpen,
                          setQuickBuyModalContent: setModalContent,
                        }
                      : {})}
                  />
                );
              })}
            </CarouselRow>
          </ProductCarouselWrapper>
        </Container>
      </Section>
      <ModalProvider backgroundComponent={OuickBuySpecialModalBackground}>
        <ModalNotification
          isOpen={isModalOpen}
          closeModal={closeModal}
          isBespokeModal
        >
          <QuickBuyModalWrapper>
            <CloseButtonWrapper>
              <CloseButton
                id="btn-quick-buy-close"
                type="button"
                onClick={closeModal}
              >
                <Close height={16} width={16} />
              </CloseButton>
            </CloseButtonWrapper>
            <ModalBody>{getModalBody(modalBodyArgs)}</ModalBody>
          </QuickBuyModalWrapper>
        </ModalNotification>
      </ModalProvider>
      {addedToBag && (
        <AddToNotification
          isOpen
          productDetails={addedToBag.items}
          key={addedToBag.updatedAt.toString()}
        />
      )}
    </>
  );
}
