import styled from 'styled-components';

import theme from 'utils/theme';

export const MuteControl = styled.button`
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.7);
  border: 0;
  width: var(--space-2xl);
  height: var(--space-2xl);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.white};
  z-index: 10;
  cursor: pointer;
  position: absolute;
  bottom: var(--space-m);
  left: var(--space-m);
  opacity: 0.75;
  transition: ${theme.transitionSpeeds.fast}s opacity;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px ${theme.colors.darkGreen};
  }

  &:hover {
    opacity: 1;
  }
`;
