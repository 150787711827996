import styled from 'styled-components';

import { mobile, tablet } from 'utils/media';
import theme from 'utils/theme';

export const MobileBreakpoint = styled.div`
  display: none;

  ${mobile} {
    display: block;
  }
`;

export const DesktopBreakpoint = styled.div`
  display: none;

  ${tablet} {
    display: block;
  }
`;

export const ImagesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

interface TextWrapperProps {
  colorScheme?: Sproutl.ColorScheme;
  horizontalAlignment: 'left' | 'center' | 'right';
  verticalAlignment: 'top' | 'center' | 'bottom';
}

const horizontalAlignmentFlex = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const verticalAlignmentFlex = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

export const TextWrapper = styled.div<TextWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${(props) =>
    props.colorScheme ? theme.colors[props.colorScheme] : theme.colors.white};
  display: flex;
  justify-content: ${(props) =>
    horizontalAlignmentFlex[props.horizontalAlignment]};
  align-items: ${(props) => verticalAlignmentFlex[props.verticalAlignment]};
  text-align: ${(props) => props.horizontalAlignment};
  padding: var(--space-2xl) var(--space-m);

  p {
    font-size: var(--step-1);
    white-space: pre-wrap;
  }

  ${tablet} {
    padding: var(--space-2xl);
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: var(--space-xl);
`;
